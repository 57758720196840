/* eslint-disable prefer-destructuring */

/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { useEffect, useState, useRef } from 'react';
import { Layout, HistoryMajorTest, CardPreference, PDFView } from '@orientaction/components';
import { useLocation } from 'react-router-dom';
import {
  useAlert,
  usePreferencePersonalityTestResult,
  useMyResulatOfTest,
  useRedirectToRootPage,
} from '@orientaction/hooks';
import withWidth from '@material-ui/core/withWidth';
import { useStyles } from './style';
import {
  CircularProgress,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  Button,
} from '@material-ui/core';
import DrawerView from './Drawer';
import Content from './Content';
import BtnCanceled from '../BtnCanceled';
import HeaderPreference from './HeaderPreference';
import FirstView from './FirstView';
import CardGoFar from './CardGoFar';
import { codePreference, codeProfil, coverBook } from '@orientaction/utils';
import { map } from 'lodash';
import ContactForm from '../ContactForm';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Print from '@material-ui/icons/Print';
import printJS from 'print-js';
import { useSelector } from 'react-redux';
import { appLanguage } from '@orientaction/reducers';

const ResultatPreference = () => {
  const classe = useStyles();

  const [loading, setLoading] = useState(false);
  const [openContactForm, setOpenContactForm] = useState(false);

  const { dialog, myResult, setDialog, setMyResult } = useMyResulatOfTest();

  const { goToRootPage } = useRedirectToRootPage();

  const { getMyResult } = usePreferencePersonalityTestResult();

  const [updatedData, setUpdatedData] = useState<boolean>(false);

  const alert = useAlert();

  const location = useLocation();

  const id = location.pathname.split('/')[2];

  const lang: string = useSelector(appLanguage);

  const getResultat = async (idTest: number) => {
    setMyResult([]);

    setLoading(true);

    (window as any).scrollTo(0, 0);

    try {
      const { data } = await getMyResult(+idTest);
      setMyResult(data.data);
    } catch (err) {
      if (
        err?.response?.data?.error?.name === 'PolicyError' &&
        err?.response?.data?.error?.status === 403
      ) {
        alert.openSimpleAlert('Cette page ne vous est pas accessible.');
        return goToRootPage();
      }
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getResultat(+id);
  }, [id]);

  const handleClose = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const handleOpen = (prop: any) => (e: any) => {
    setDialog({
      ...dialog,
      open: true,
      data: {
        keyWord: prop.key,
        color: prop.color,
        title: prop.youAre,
        nbr: prop.nbr,
      },
    });
  };
  const [codeProf, setCodeProf] = useState<any>();
  const [openPDF, setOpenPDF] = useState<boolean>(false);
  const getCode = (resultat: any[]) => {
    // const data = map(resultat, (elem: any) => {
    //   return codePreference[elem.category][elem.key];
    // });
    console.log({ resultat });
    if (resultat) {
      const data = [
        codePreference[resultat[0]?.category][resultat[0]?.key],
        codePreference[resultat[1]?.category][resultat[1]?.key],
        codePreference[resultat[2]?.category][resultat[2]?.key],
        codePreference[resultat[4]?.category][resultat[4]?.key],
        codePreference[resultat[3]?.category][resultat[3]?.key],
      ];
      console.log({ data, code: data.join('') });
      return data.join('');
    }
    // const lastValue = data[4];
    // data[4] = data[3];
    // data[3] = lastValue;
    // console.log({ data, code: data.join('') });
  };

  useEffect(() => {
    const codePref = getCode(myResult?.resultat);
    console.log({ codePref, codeprofil: codeProfil[codePref as string] });
    setCodeProf(codeProfil[codePref as string]);
  }, [myResult]);

  const openContactModal = () => {
    setOpenContactForm((prev: boolean) => !prev);
  };
  const componentRef = useRef(null);

  const printPDF = () => {
    printJS({
      printable: codeProf.pdf,
      type: 'pdf',
      style: 'width: 100%',
      showModal: true,
      modalMessage: 'Préparation du fichier',
    });
  };

  useEffect(() => {
    if (updatedData) {
      getResultat(+id);
      setUpdatedData(false);
    }
  }, [updatedData]);

  return (
    <Layout isaddBackground={true} noPadding={true} isClassic={true} footer={true}>
      <HeaderPreference />
      {loading ? (
        <div className={classe.contentLoader}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={classe.root}>
            <div className={classe.btnHistory}>
              <HistoryMajorTest
                testResults={myResult?.listHistory || []}
                idTest={+id}
                toRedirect="/resultat-preference"
              />
            </div>
            <BtnCanceled id={myResult?.users_permissions_user} isBack={true} />
            <FirstView
              listPrincipaux={myResult?.resultat || []}
              id={id}
              handleUpdated={(val: boolean) => setUpdatedData(val)}
              consultant={myResult?.consultant}
              updatedDate={myResult?.updatedDate}
              upperHierarchie={myResult?.user?.upper_hierarchy?.id}
              isPrefJeune={false}
            />
            <Typography
              variant="h2"
              color="textPrimary"
              className={classe.titleText}
              id="softskills">
              DÉCOUVREZ VOS 5 PRÉFÉRENCES
            </Typography>
            <Grid container={true} style={{ marginTop: 40, marginBottom: 35 }} spacing={3}>
              {myResult?.resultat?.map((res: any) => (
                <Grid key={res.category} item={true} xs={12} md={4}>
                  <CardPreference pref={res} />
                </Grid>
              ))}
            </Grid>
            <Grid
              container={true}
              justifyContent="center"
              alignItems="center"
              style={{ marginBottom: 50, marginTop: 80 }}>
              <Grid item={true} md={10}>
                <CardGoFar
                  handleContactClick={openContactModal}
                  linkVis={lang === 'GB' ? codeProf?.linkVisEn : codeProf?.linkVis}
                  linkBuy={codeProf?.linkBuy}
                  img={`${coverBook}/${lang === 'GB' ? codeProf?.imgEn : codeProf?.img}`}
                />
              </Grid>
            </Grid>
            {/* <Content listPrincipaux={myResult?.resultat || []} handleOpenDrawer={handleOpen} /> */}
          </div>
          <Dialog
            open={openPDF}
            onClose={() => setOpenPDF(false)}
            aria-labelledby="alert-dialog-title"
            className={classe.maxWidthPaper}
            aria-describedby="alert-dialog-description">
            <DialogContent
              style={{ position: 'relative', padding: 0, maxWidth: '100%', overflowX: 'hidden' }}>
              <IconButton
                aria-label="delete"
                className={classe.closeBtn}
                onClick={() => setOpenPDF(false)}>
                <Close />
              </IconButton>
              <IconButton aria-label="delete" className={classe.printBtn} onClick={printPDF}>
                <Print />
              </IconButton>
              {/* {loadingPDF && (
                <div className={classe.contentLoader}>
                  <CircularProgress />
                </div>
              )} */}
              <div ref={componentRef}>
                <PDFView pdf={codeProf?.pdf} />
              </div>
            </DialogContent>
          </Dialog>
          <ContactForm
            test={{ id: 4, label: 'Préférences®, test des personnalités' }}
            open={openContactForm}
            handleClose={openContactModal}
          />
        </>
      )}

      <DrawerView open={dialog.open} handleClose={handleClose} />
    </Layout>
  );
};

export default withWidth()(ResultatPreference);
